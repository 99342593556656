import { FormattedMessage } from "react-intl"

export const tradingPageTabs = [
    {
        title: <FormattedMessage id="menu.accounts" defaultMessage="Accounts"/>,
        src: '/accounts'
    },
    {
        title: <FormattedMessage id="menu.history" defaultMessage="History"/>,
        src: '/history'
    }
]