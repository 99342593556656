import { FormattedMessage, useIntl } from 'react-intl';
import './WithdrawModal.scss';
import { FormInput } from 'components/Form/FormInput/FormInput';
import { withdrawWalletStep } from './WithdrawModal';
import { REQUIRED_FIELD_VALIDATION } from 'utils/validators';
import { useFormContext, useWatch } from 'react-hook-form';
import { SelectInput } from 'components/Form/SelectInput/SelectInput';
import { withdrawWalletOptions } from 'constants/finance';
import { Button } from 'components/Button/Button';

type Props = {
  isLoading: boolean;
  walletStep: withdrawWalletStep;
  setWalletStep: (step: withdrawWalletStep) => void;
};

export const WithdrawModalStep2 = ({ isLoading, walletStep, setWalletStep }: Props) => {
  const { control, clearErrors } = useFormContext();

  const intl = useIntl();

  const { fromWithdraw, amount } = useWatch({ control });

  const getMainFieldAttributes = () => {
    if (walletStep === 'card_select') {
      return {
        name: 'card',
        placeholderId: 'page.finance.withdraw.popup.deposit.card_number',
        defaultMessage: 'Card Number',
      };
    }
    if (walletStep === 'iban_select') {
      return {
        name: 'card',
        placeholderId: 'page.finance.withdraw.popup.deposit.iban_number',
        defaultMessage: 'IBAN Number',
      };
    }
    return {
      name: 'wallet',
      placeholderId: 'page.finance.withdraw.popup.deposit.wallet_number',
      defaultMessage: 'Wallet Number',
    };
  };

  const { name, placeholderId, defaultMessage } = getMainFieldAttributes();

  return (
    <div>
      <div className="modal-template-content-subtitle">
        {walletStep === 'crypto_select' ? (
          <FormattedMessage
            id="page.finance.withdraw.popup.deposit.title"
            defaultMessage="Enter Wallet number & currency"
          />
        ) : null}
      </div>
      <FormInput
        name={name}
        type="text"
        placeholder={intl.formatMessage({ id: placeholderId, defaultMessage })}
        label={intl.formatMessage({ id: placeholderId, defaultMessage })}
        rules={REQUIRED_FIELD_VALIDATION}
        control={control}
      />
      <div className="_flex _flex-a-c">
        <div className="_flex _flex-a-c">
          {amount} {fromWithdraw?.data?.amount_type ? fromWithdraw?.data?.amount_type : 'USD'}
          {walletStep === 'crypto_select' ? <i className="fi fi-rr-right _m-x-10" /> : null}
        </div>
        {walletStep === 'crypto_select' ? (
          <SelectInput
            className="_flex-gr-1"
            name="crypto_type"
            id="crypto_type"
            label={intl.formatMessage({
              id: 'page.finance.withdraw.popup.deposit.currency',
              defaultMessage: 'Currency',
            })}
            control={control}
            rules={REQUIRED_FIELD_VALIDATION}
            options={withdrawWalletOptions}
          />
        ) : null}
      </div>
      <div className="form-buttons _flex _flex-a-c _flex-j-e _m-t-50">
        <Button
          buttonType="outline"
          onClick={() => {
            clearErrors();
            setWalletStep('start');
          }}
          className="_m-r-10"
        >
          <FormattedMessage id="page.finance.withdraw.popup.deposit.back" defaultMessage="Back" />
        </Button>
        <Button tag="button" type="submit" showSpinner={isLoading} disabled={isLoading}>
          <FormattedMessage
            id="page.finance.withdraw.popup.btn.checkoutNow"
            defaultMessage="Checkout now"
          />
        </Button>
      </div>
    </div>
  );
};
