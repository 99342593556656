import * as Sentry from '@sentry/react';
import { IS_DEV_MODE } from 'config';

export const SENTRY_INIT_CONFIG = {
    dsn: process.env.REACT_APP_ENV === 'dev' ? '' : process.env.REACT_SENTRY_DSN,
    integrations: [
        new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: [IS_DEV_MODE ? process.env.REACT_APP_API_URL : window?.apiUrl],
        }),
        new Sentry.Replay(),
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
};
