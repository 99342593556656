import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '../../components/Button/Button';
import { addDeposit, depositMethods } from '../../api/deposit';
import { cryptoWallets, paymentsMethodDeposit } from '../../constants/finance';
import { addToast } from '../../store/toast';
import { getResponseErrorMessage } from '../../utils/getResponseErrorMessage';
import { useAppDispatch, useAppSelector } from '../../store';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { openModal, selectModalProps } from '../../store/modal';
import { DEPOSIT_MODAL_KEY } from '../DepositModal/DepositModal';
import { TransactionInfo } from './TransactionInfo';
import './WalletModal.scss';

export const WALLET_MODAL_KEY = 'WALLET_MODAL_KEY';

export const WalletModalContent = () => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const [isMethodLoading, setIsMethodLoading] = useState(true);
  const [walletList, setWalletList] = useState([]);
  const [selectedWalletId, setSelectedWalletId] = useState(null);
  const [cryptoTransaction, setCryptoTransaction] = useState(null);
  const [isPaymentRequestLoading, setPaymentRequestLoading] = useState(false);
  const [fullWallet, setFullWallet] = useState(false);

  const paymentData = useAppSelector(selectModalProps);

  const { handleSubmit, register, reset } = useForm({
    reValidateMode: 'onChange',
    defaultValues: { target_wallet: undefined },
  });

  const onSubmit = async (data) => {
    setPaymentRequestLoading(true);
    addDeposit({
      ...paymentData,
      method: paymentsMethodDeposit[0].value,
      target_wallet: data['target_wallet'],
    })
      .then((res) => {
        setPaymentRequestLoading(false);
        if (res?.data?.data) {
          const resp = res.data.data;
          if (resp.crypto_transaction && resp.crypto_transaction.wallet) {
            setCryptoTransaction({
              cryptoType: resp.crypto_transaction.wallet.crypto_type,
              wallet: resp.crypto_transaction.wallet.wallet,
              amount: resp.crypto_transaction.crypto_amount,
            });
          }
        }

        dispatch(
          addToast({
            type: 'success',
            title: intl.formatMessage({ id: 'status.success.title', defaultMessage: 'Success' }),
            message: intl.formatMessage({ id: 'transaction.deposit.alert.created' }),
            timeout: 5000,
          }),
        );
      })
      .catch((err) => {
        setPaymentRequestLoading(false);
        dispatch(
          addToast({
            type: 'error',
            message: getResponseErrorMessage(err),
            timeout: 5000,
          }),
        );
      });
  };

  const handleOpenDepositModal = () => {
    dispatch(openModal({ key: DEPOSIT_MODAL_KEY, data: paymentData }));
  };

  useEffect(() => {
    depositMethods()
      .then((res) => {
        if (res.data.data && res.data.data['electronic'] && res.data.data['electronic'].length) {
          const wallets = res.data.data['electronic'].sort((a, b) => b.fintegrity - a.fintegrity);
          setWalletList(wallets);
          setSelectedWalletId(wallets[0].id);
          reset({ target_wallet: wallets[0].id.toString() });
        }
        setIsMethodLoading(false);
      })
      .catch(() => {
        setIsMethodLoading(false);
      });
  }, []);

  return (
    <div className="hide-horiz-scroll">
      {!cryptoTransaction && walletList?.length ? (
        <div className="wallet-list _flex _flex-w row">
          {walletList.map((item) => (
            <div className="wallet col c-xs-6" key={item.id}>
              <input
                type="radio"
                id={item.id}
                autoComplete="off"
                value={item.id}
                {...register('target_wallet')}
              />
              <label htmlFor={item.id}>
                <div
                  className={`wallet-item ${selectedWalletId === item.id ? 'selected' : ''}`}
                  onClick={() => {
                    if (selectedWalletId !== item.id) {
                      setSelectedWalletId(item.id);
                    }
                  }}
                >
                  <div className="wallet-icon">
                    <i className={`${item.crypto_type?.toLowerCase()}`}></i>
                  </div>
                  <div className="wallet-desc">
                    <span>
                      {cryptoWallets[item.crypto_type?.toLowerCase()]}{' '}
                      {item.crypto_type === 'USDT' &&
                        item.crypto_network &&
                        `(${item.crypto_network})`}
                    </span>
                    <span onClick={() => setFullWallet(true)}>
                      {!fullWallet
                        ? item?.wallet.slice(0, 5) + '...' + item?.wallet.slice(-5)
                        : item?.wallet}
                    </span>
                    <span>{item?.description}</span>
                  </div>
                </div>
              </label>
            </div>
          ))}
        </div>
      ) : null}
      {cryptoTransaction ? <TransactionInfo cryptoTransaction={cryptoTransaction} /> : null}
      {!cryptoTransaction && !walletList?.length ? (
        <div className="transaction__empty _flex _flex-col _flex-xy-c">
          <span>
            {isMethodLoading ? (
              'Loading..'
            ) : (
              <FormattedMessage id="page.trading.popup.wallet.none" defaultMessage="No Wallets" />
            )}
          </span>
        </div>
      ) : null}
      {!cryptoTransaction ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="_flex _flex-j-e">
            <Button className="_m-r-10" buttonType="outline" onClick={handleOpenDepositModal}>
              <FormattedMessage
                id="page.trading.popup.changePassword.btn.cancel"
                defaultMessage="Cancel"
              />
            </Button>
            <Button
              type="submit"
              tag="button"
              showSpinner={isPaymentRequestLoading}
              isDisabled={isPaymentRequestLoading}
            >
              <FormattedMessage id="page.trading.popup.wallet.ok" defaultMessage="OK" />
            </Button>
          </div>
        </form>
      ) : null}
    </div>
  );
};
