import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useAppDispatch } from '../../../store';
import { closeModal, openModal } from '../../../store/modal';
import { PageNavigation } from '../../../components/PageNavigation/PageNavigation';
import { tradingPageTabs } from '../_shared/TradingPageTabs';
import { Button } from '../../../components/Button/Button';
import HistoryFilterModal, {
  HISTORY_FILTERS_MODAL_KEY,
} from '../../../modals/HistoryFilterModal/HistoryFilterModal';
import { ModalTemplate } from '../../../components/Modal/components/ModalTemplate/ModalTemplate';
import { tradingHistoryWT } from '../../../api/trading';
import TablePagination from '../../../components/Table/TablePagination';
import { usePageTitle } from '../../../hooks/usePageTitle';
import { FilterTag } from '../../../components/FilterTag/FilterTag';
import { TradingHistoryTable } from './TradingTable/TradingHistoryTable';
import { Trade, TradingFilter } from '../../../types/Trading';
import { useGetTradingAccounts } from 'hooks/useGetTradingAccounts';

// бэк делал WT, useTableData не подходит, структура ответа/фильтров/пагинации другая
const History = () => {
  const dispatch = useAppDispatch();
  const intl = useIntl();

  const [tableData, setTableData] = useState<Trade[]>([]);
  const [pagination, setPagination] = useState({
    current_page: 1,
    per_page: 10,
    total_items: 0,
    total_pages: 1,
  });
  const [isLoading, setIsLoading] = useState(false);

  const [filters, setFilters] = useState<TradingFilter>({});

  const { tradingAccounts: accountsData } = useGetTradingAccounts();

  const getTradingHistory = (params?: any) => {
    setIsLoading(true);
    tradingHistoryWT({
      per_page: pagination.per_page,
      current_page: pagination.current_page,
      ...filters,
      ...params,
    })
      .then((response) => {
        const { items, pagination } = response.data.data;

        setTableData(items);
        setPagination(pagination);
      })
      .catch(console.log)
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getTradingHistory();
  }, []);

  usePageTitle(
    intl.formatMessage({
      id: 'page.trading.history.title',
      defaultMessage: 'Trading History',
    }),
  );

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const onTableParamsChange = (params) => {
    const navigateToPage = {
      next: pagination.current_page + 1,
      prev: pagination.current_page - 1,
      last: pagination.total_pages,
      first: 1,
    };

    const current_page = navigateToPage[params?.navigate]
      ? navigateToPage[params.navigate]
      : params?.page
      ? Number(params.page)
      : pagination.current_page;

    const tableParams = {
      per_page: params?.perPage ? Number(params.perPage) : pagination.per_page,
    };

    getTradingHistory({ ...tableParams, current_page: params?.perPage ? 1 : current_page });
  };

  const onSubmitFilter = (filter) => {
    setFilters(filter);
    getTradingHistory({ ...filter, current_page: 1 });
  };

  const openFilterModal = () => {
    dispatch(
      openModal({
        key: HISTORY_FILTERS_MODAL_KEY,
        data: {
          logins: filters?.logins
            ? accountsData.find((acc) => acc.trading_account === filters?.logins[0])
            : null,
          open: filters?.open_date_from
            ? { from: filters.open_date_from, to: filters.open_date_to }
            : null,
          close: filters?.closing_date_from
            ? { from: filters.closing_date_from, to: filters.closing_date_to }
            : null,
          symbols: filters?.symbols || null,
          state: filters?.state || null,
        },
      }),
    );
  };

  return (
    <>
      <h1>
        <FormattedMessage id="menu.trading" defaultMessage="Trading" />
      </h1>
      <PageNavigation nav={tradingPageTabs}>
        <div className="_flex _flex-w _flex-a-c">
          {filters.logins && (
            <FilterTag
              title={<FormattedMessage id="page.trading.tag.account" defaultMessage="Account:" />}
              onRemove={() => onSubmitFilter({ ...filters, logins: undefined })}
            >
              {filters?.logins[0]}
            </FilterTag>
          )}
          {filters.symbols && (
            <FilterTag
              title={<FormattedMessage id="page.trading.tag.symbol" defaultMessage="Symbol:" />}
              onRemove={() => onSubmitFilter({ ...filters, symbols: undefined })}
            >
              {filters?.symbols[0]}
            </FilterTag>
          )}
          {filters.state && (
            <FilterTag
              title={<FormattedMessage id="page.trading.tag.state" defaultMessage="State:" />}
              onRemove={() => onSubmitFilter({ ...filters, state: undefined })}
            >
              {filters?.state}
            </FilterTag>
          )}
          {filters.closing_date_from && (
            <FilterTag
              title={<FormattedMessage id="page.trading.tag.closed" defaultMessage="Closed:" />}
              onRemove={() =>
                onSubmitFilter({
                  ...filters,
                  closing_date_from: undefined,
                  closing_date_to: undefined,
                })
              }
            >
              {`${filters.closing_date_from} ${filters.closing_date_to}`}
            </FilterTag>
          )}
          {filters.open_date_from && (
            <FilterTag
              title={<FormattedMessage id="page.trading.tag.opened" defaultMessage="Opened:" />}
              onRemove={() =>
                onSubmitFilter({ ...filters, open_date_from: undefined, open_date_to: undefined })
              }
            >
              {`${filters.open_date_from} ${filters.open_date_to}`}
            </FilterTag>
          )}
        </div>
        <Button buttonType="outline" onClick={openFilterModal} iconOnly>
          <i className="fi fi-rr-filter _flex _fz-18" />
        </Button>
      </PageNavigation>
      <ModalTemplate
        id={HISTORY_FILTERS_MODAL_KEY}
        title={
          <FormattedMessage id="page.trading.history.form.modal.title" defaultMessage="Filter" />
        }
        onClose={handleCloseModal}
        size="md"
      >
        <HistoryFilterModal
          accountsData={accountsData}
          closeModal={handleCloseModal}
          onSubmitFilter={onSubmitFilter}
        />
      </ModalTemplate>
      <TradingHistoryTable
        data={tableData}
        perPage={String(pagination.per_page)}
        showLoader={isLoading}
      />
      {tableData?.length ? (
        <TablePagination
          onChange={onTableParamsChange}
          setPerPageCount={(per_page) =>
            setPagination((prev) => ({ ...prev, per_page: Number(per_page) }))
          }
          perPage={String(pagination.per_page)}
          tableLinks={{
            prev: pagination.current_page === 1 ? null : 'true',
            next: pagination.current_page === pagination.total_pages ? null : 'true',
          }}
          tableMeta={{ current_page: pagination.current_page, last_page: pagination.total_pages }}
        />
      ) : null}
    </>
  );
};

export default History;
