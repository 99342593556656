import { useEffect } from 'react';

export const useAutologin = () => {
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');

    if (token) {
      try {
        localStorage.setItem('token', token);
        setTimeout(() => {
          window.location.replace('/cryptocurrency');
        }, 100);
      } catch (error) {
        console.error('ERROR: ', error);
      }
    }
  }, [window.location.search]);
};
