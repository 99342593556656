import { useFormContext, useWatch } from 'react-hook-form';
import { WITHDRAW_CARD_METHODS, withdrawWalletStep } from './WithdrawModal';
import { FormattedMessage } from 'react-intl';
import { SelectInput } from 'components/Form/SelectInput/SelectInput';
import { REQUIRED_FIELD_VALIDATION } from 'utils/validators';
import { AmountInput } from 'components/Form/AmountInput/AmountInput';
import { Button } from 'components/Button/Button';
import { useAppSelector } from 'store';
import {
  selectPaymentCreditCardWithdraw,
  selectPaymentBankCardWithdraw,
  selectPaymentIbanWithdraw,
} from 'store/payments';
import { compact } from 'lodash';
import { AccountSelectOption } from 'components/Form/_helpers/AccountSelectOption';
import { DepositAmountOptions } from 'constants/finance';
import { formatAccountSelectOptions } from 'components/Form/_helpers/formatAccountSelectOptions';
import { useGetTradingAccounts } from 'hooks/useGetTradingAccounts';
import './WithdrawModal.scss';

type Props = {
  isLoading: boolean;
  walletStep: withdrawWalletStep;
  setWalletStep: (step: withdrawWalletStep) => void;
  onSubmit: (data: any) => void;
  closeModal: () => void;
};

export const WithdrawModalStep1 = ({
  isLoading,
  walletStep,
  setWalletStep,
  onSubmit,
  closeModal,
}: Props) => {
  const {
    control,
    setValue,
    trigger,
    handleSubmit,
    formState: { errors },
  } = useFormContext();

  const { tradingAccounts } = useGetTradingAccounts();

  const { fromWithdraw, payment_method, amount } = useWatch({ control });

  const creditCardEnabled = useAppSelector(selectPaymentCreditCardWithdraw);
  const bankCardEnabled = useAppSelector(selectPaymentBankCardWithdraw);
  const ibanEnabled = useAppSelector(selectPaymentIbanWithdraw);

  const paymentMethodsWithdraw = compact([
    { value: 'Crypto', label: 'Cryptocurrency' },
    { value: 'Wire', label: 'Wire transfer' },
    creditCardEnabled && { label: 'Credit card', value: 'credit_card_ca' },
    bankCardEnabled && { label: 'Bank card', value: 'bank_card_ca' },
    ibanEnabled && { label: 'IBAN', value: 'iban_ca' },
  ]);

  const isWireMethod = payment_method?.value === 'Wire';
  const isIbanMethod = payment_method?.value === 'iban_ca';
  const isCardMethod = WITHDRAW_CARD_METHODS.includes(payment_method?.value);

  const onStepSubmit = () => {
    if (isWireMethod) {
      handleSubmit(onSubmit);
    } else if (payment_method?.value === 'Crypto') {
      setWalletStep('crypto_select');
    } else if (isCardMethod) {
      setWalletStep('card_select');
    } else if (isIbanMethod) {
      setWalletStep('iban_select');
    }
  };

  const getSubmitButtonText = () => {
    if (isWireMethod) {
      return (
        <FormattedMessage
          id="page.finance.withdraw.popup.btn.checkoutNow"
          defaultMessage="Checkout Now"
        />
      );
    }

    if (isCardMethod) {
      return (
        <FormattedMessage id="page.finance.withdraw.popup.btn.toCard" defaultMessage="To Card" />
      );
    }

    if (isIbanMethod) {
      return (
        <FormattedMessage id="page.finance.withdraw.popup.btn.toIban" defaultMessage="To IBAN" />
      );
    }

    return (
      <FormattedMessage id="page.finance.withdraw.popup.btn.toWallet" defaultMessage="To Wallet" />
    );
  };

  return (
    <div className={walletStep !== 'start' ? 'hide' : ''}>
      <div className="modal-template-content-subtitle">
        <FormattedMessage
          id="page.finance.withdraw.popup.descr.text"
          defaultMessage="Request withdrawal from one of you trading accounts by selecting an account from the list, choosing a payment account and defining the desired withdrawal amount."
        />
      </div>
      <SelectInput
        id="payment_method_deposit_modal"
        name="payment_method"
        label={
          <FormattedMessage
            id="page.finance.widthdraw.popup.paymentMethod"
            defaultMessage="Payment Method"
          />
        }
        control={control}
        rules={REQUIRED_FIELD_VALIDATION}
        options={paymentMethodsWithdraw}
      />
      <SelectInput
        id="fromWithdraw_withdraw_modal"
        name="fromWithdraw"
        label={
          <FormattedMessage
            id="page.finance.withdraw.popup.field.fromTradingAccount.title"
            defaultMessage="Choose trading account"
          />
        }
        control={control}
        rules={REQUIRED_FIELD_VALIDATION}
        options={formatAccountSelectOptions(tradingAccounts)}
        formatOptionLabel={AccountSelectOption}
        maxMenuHeight={180}
      />
      <AmountInput
        id="amount_withdraw_modal"
        name="amount"
        label={
          <FormattedMessage
            id="page.finance.withdraw.popup.field.amount.title"
            defaultMessage="Amount"
          />
        }
        currency={fromWithdraw?.data?.amount_type}
        disabled={!fromWithdraw}
        rules={{
          ...REQUIRED_FIELD_VALIDATION,
          validate: (value) =>
            value <= Number(fromWithdraw?.data?.balance?.replace(',', '')) || (
              <FormattedMessage
                id="page.finance.withdraw.popup.validation"
                defaultMessage="Not enough funds"
              />
            ),
        }}
        onChange={() => {
          trigger('amount');
        }}
        setValue={setValue}
        control={control}
        buttons={DepositAmountOptions}
      />
      <div className="controls-warning _m-y-15 _fz-12 _p-15">
        <FormattedMessage
          id="page.finance.withdraw.popup.note.text"
          defaultMessage="Note: Withdrawal request will be reviewed."
        />
      </div>
      <div className="form-buttons _flex _flex-a-c _flex-j-e _m-t-50">
        <Button buttonType="outline" onClick={closeModal} className="_m-r-10">
          <FormattedMessage id="page.finance.withdraw.popup.btn.cancel" defaultMessage="Cancel" />
        </Button>
        <Button
          tag="button"
          type={isWireMethod ? 'submit' : 'button'}
          showSpinner={isLoading}
          isDisabled={
            isLoading || !!Object.keys(errors).length || !payment_method || !fromWithdraw || !amount
          }
          onClick={onStepSubmit}
        >
          {getSubmitButtonText()}
        </Button>
      </div>
    </div>
  );
};
