import LangWrapper from "./components/LangWrapper";
import { useAppCache } from "./hooks/useAppCache";
import { useAppScrollTop } from "./hooks/useAppScrollTop";
import { useAppDispatch, useAppSelector } from "./store";
import { selectIsAuthorized } from "./store/auth";
import { useEffect } from "react";
import { getKashaPayments, getPaymentsParameters } from "./store/payments";
import { ThemeWrapper } from "./components/ThemeWrapper";
import { getMode, getTheme, selectThemeIsLoading } from "./store/theme";
import { Outlet } from "react-router-dom";
import { useFavicon } from "./hooks/useFavicon";
import { useCheckIpCountry } from "./hooks/useCheckIpCountry";
import { SENTRY_INIT_CONFIG } from './sentry-init.config';
import * as Sentry from '@sentry/react';
import api from "api/api";
import Preloader from './components/Preloader'
import { useAutologin } from "hooks/useAutologin";

Sentry.init(SENTRY_INIT_CONFIG);

const App = () => {
    const dispatch = useAppDispatch();
    const isAuth = useAppSelector(selectIsAuthorized);
    const isLoading = useAppSelector(selectThemeIsLoading)

    useAutologin()

    useAppCache();
    useCheckIpCountry(isAuth);

    useEffect(() => {
        dispatch(getPaymentsParameters());
        // костыль
        dispatch(getTheme(api.defaults.baseURL))

        if (isAuth) {
            dispatch(getKashaPayments());
            dispatch(getMode());
        }
    }, [isAuth]);

    useAppScrollTop();
    useFavicon();

    return (
        <LangWrapper>
            <ThemeWrapper>
                {isLoading ? <Preloader isNeutralColor /> : <Outlet />}
            </ThemeWrapper>
        </LangWrapper>
    )
}

export default App;