import cn from "classnames";
import { LayoutMenuOption } from "./LayoutMenuOption";
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "../../../store";
import { selectChatUnreadThreads } from "../../../store/chat/chatSlice";
import { isChatEnabled } from "../../Chat/helpers/isChatEnabled";
import { selectTheme } from "../../../store/theme";
import './Menu.scss';

export const Menu = ({ isOpened }) => {
    const location = useLocation();
    const chatUnreadCount = useAppSelector(selectChatUnreadThreads);
    const { logo_src } = useAppSelector(selectTheme);

    return (
        <div className={cn("private-layout-menu _h-100vh _flex _flex-col", {opened: isOpened})}>
            <div className="private-layout-menu-logo _flex-sh-0">
                <img src={logo_src} alt='logo'/>
            </div>
            <div className="private-layout-menu-list _flex _flex-col _flex-gr-1">
                <LayoutMenuOption
                    src='/cryptocurrency'
                    title={<FormattedMessage id="menu.payments" defaultMessage="Payments"/>}
                    icon="fi-rr-calculator"
                    activeIcon="fi-sr-calculator"
                    active={location.pathname === '/cryptocurrency' || location.pathname === '/wire-statuses'}
                />
                <LayoutMenuOption
                    src='/accounts'
                    icon="fi-rr-chart-network"
                    activeIcon="fi-sr-chart-network"
                    title={<FormattedMessage id="menu.trading" defaultMessage="Trading"/>}
                    active={['/accounts', '/history', '/opened-positions', '/deals'].includes(location.pathname)}
                />
                <LayoutMenuOption
                    src='/documents'
                    icon="fi-rr-folder"
                    activeIcon="fi-sr-folder"
                    title={<FormattedMessage id="menu.documents" defaultMessage="Documents"/>}
                    active={location.pathname === '/documents'}
                />
                <div className="menu-bottom _m-t-auto">
                    <LayoutMenuOption
                        src="/meta-trader"
                        icon="fi-rr-download"
                        activeIcon="fi-br-download"
                        title={<FormattedMessage id="menu.metaTrader" defaultMessage="Trading Platform"/>}
                        active={location.pathname === '/meta-trader'}
                    />
                    {isChatEnabled() ? (
                        <LayoutMenuOption
                            src='/chat'
                            icon="fi-rr-comments"
                            activeIcon="fi-sr-comments"
                            title={<FormattedMessage id="chat.menu" defaultMessage="Support chat"/>}
                            active={location.pathname === '/chat'}
                            count={chatUnreadCount}
                        />
                    ) : null}
                </div>
            </div>
        </div>
    )
}