import { useMemo } from 'react';
import moment from 'moment';

import Table from '../../../../components/Table/Table';
import { TABLE_TRADING_COLUMNS_NAMES } from './TradingHistoryColumns';
import { makeTableData } from '../../../../utils/makeTableData';
import { Trade, TradingTableProps } from '../../../../types/Trading';

import './trading-table.scss';

const DateCell = ({ date }: { date: string }) => (
  <div className="date-cell-col">
    <span>{moment(date, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY')}</span>
    <span className="_grey-title">{moment(date, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss')}</span>
  </div>
);

export const TradingHistoryTable = ({ data, perPage, showLoader }: TradingTableProps<Trade>) => {
  const preparedTableData = useMemo(
    () =>
      makeTableData(data, data.length).map((item: Trade) => {
        const {
          order_id,
          type,
          login,
          symbol,
          volume,
          state,
          units,
          lots,
          opening_price,
          closing_price,
          stop_loss_value,
          take_profit_value,
          commission,
          opening_date,
          closing_date,
          pl,
        } = item;

        return {
          order_id: <b>{order_id}</b>,
          account: login,
          symbol: <span>{symbol}</span>,
          opening_date: <DateCell date={opening_date} />,
          closing_date: closing_date ? <DateCell date={closing_date} /> : '',
          type: <span className={type?.toLowerCase()}>{type}</span>,
          state,
          units,
          volume,
          lots,
          opening_price,
          closing_price: closing_price ?? '',
          sl: stop_loss_value ?? '',
          tp: take_profit_value ?? '',
          commission,
          profit: <span className={pl > 0 ? 'pos' : pl < 0 ? 'neg' : ''}>{pl}</span>,
        };
      }),
    [data],
  );

  return (
    <Table
      columns={TABLE_TRADING_COLUMNS_NAMES}
      data={preparedTableData}
      perPage={perPage}
      showLoader={showLoader}
      getCellProps={() => ({ style: {} })}
      className="trading-table"
    />
  );
};
