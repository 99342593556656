import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '../../components/Button/Button';
import { addDeposit, depositCryptoWallets } from '../../api/deposit';
import { paymentsMethodDeposit } from '../../constants/finance';
import { addToast } from '../../store/toast';
import { getResponseErrorMessage } from '../../utils/getResponseErrorMessage';
import { useAppDispatch, useAppSelector } from '../../store';
import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { openModal, selectModalProps } from '../../store/modal';
import { DEPOSIT_MODAL_KEY } from '../DepositModal/DepositModal';
import { TransactionInfo } from './TransactionInfo';
import { selectPaymentsCrypto } from 'store/payments';
import { SelectInput } from 'components/Form/SelectInput/SelectInput';
import { ICryptoWallet } from 'types/payments';
import { REQUIRED_FIELD_VALIDATION } from 'utils/validators';
import './WalletModal.scss';

export const WALLET_MODAL_KEY = 'WALLET_MODAL_KEY';

const generateOption = (value: string) => ({ label: value, value });

const initialWalletState = {
  data: [] as ICryptoWallet[],
  page: 1,
  isLoading: false,
  hasMore: false,
};

export const WalletModalContentFintegrity = () => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const [cryptoWallets, setCryptoWallets] = useState(initialWalletState);
  const [selectedWalletId, setSelectedWalletId] = useState<number | null>(null);
  const [cryptoTransaction, setCryptoTransaction] = useState(null);
  const [isPaymentRequestLoading, setPaymentRequestLoading] = useState(false);
  const [fullWallet, setFullWallet] = useState(false);

  const crypto = useAppSelector(selectPaymentsCrypto);
  const paymentData = useAppSelector(selectModalProps);

  const {
    control,
    register,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: { target_wallet: null, currency: null, network: null },
  });
  const watch = useWatch({ control });

  const currencyOptions = Object.keys(crypto).map(generateOption);
  const networkOptions = (crypto[watch.currency?.value] || []).map(generateOption);

  const onSubmit = async (data) => {
    setPaymentRequestLoading(true);
    addDeposit({
      ...paymentData,
      method: paymentsMethodDeposit[0].value,
      target_wallet: data['target_wallet'],
    })
      .then((res) => {
        // closeModal();
        setPaymentRequestLoading(false);
        if (res?.data?.data) {
          const resp = res.data.data;
          if (resp.crypto_transaction && resp.crypto_transaction.wallet) {
            setCryptoTransaction({
              cryptoType: resp.crypto_transaction.wallet.crypto_type,
              wallet: resp.crypto_transaction.wallet.wallet,
              amount: resp.crypto_transaction.crypto_amount,
            });
          }
        }

        dispatch(
          addToast({
            type: 'success',
            title: intl.formatMessage({ id: 'status.success.title', defaultMessage: 'Success' }),
            message: intl.formatMessage({ id: 'transaction.deposit.alert.created' }),
            timeout: 5000,
          }),
        );
      })
      .catch((err) => {
        setPaymentRequestLoading(false);
        dispatch(
          addToast({
            type: 'error',
            message: getResponseErrorMessage(err),
            timeout: 5000,
          }),
        );
      });
  };

  const handleOpenDepositModal = () => {
    dispatch(openModal({ key: DEPOSIT_MODAL_KEY, data: paymentData }));
  };

  const cryptoWalletsParams = {
    currency: watch.currency?.value,
    network: watch.network?.value,
    target_wallet: watch.target_wallet,
    page: cryptoWallets.page,
    per_page: 20,
  };

  const depositCryptoWalletsRequest = () => {
    setCryptoWallets((prev) => ({ ...prev, isLoading: true }));

    depositCryptoWallets(cryptoWalletsParams)
      .then((response) => {
        const { data, meta } = response.data;
        const hasMore = meta.current_page < meta.last_page;

        setCryptoWallets((prev) => ({
          ...prev,
          data: meta.current_page > 1 ? data.concat(prev.data) : data,
          page: hasMore ? prev.page + 1 : prev.page,
          hasMore,
        }));
      })
      .catch(console.log)
      .finally(() => setCryptoWallets((prev) => ({ ...prev, isLoading: false })));
  };

  useEffect(() => {
    const { currency, network } = watch;

    if (!currency?.value || !network?.value) return;

    depositCryptoWalletsRequest();
  }, [watch.currency, watch.network]);

  const onChangeCurrency = (option) => {
    setValue('currency', option);
    setCryptoWallets(initialWalletState);

    if (crypto[option.value].length === 1) {
      const value = crypto[option.value][0];

      setValue('network', { label: value, value });
      errors?.network && clearErrors('network');
    } else {
      setValue('network', null);
    }
  };

  return (
    <div className="hide-horiz-scroll">
      {!cryptoTransaction && (
        <>
          <SelectInput
            id="currency"
            name="currency"
            label={
              <FormattedMessage
                id="page.trading.popup.wallet.chooseCurrency"
                defaultMessage="Choose currency"
              />
            }
            control={control}
            options={currencyOptions}
            rules={REQUIRED_FIELD_VALIDATION}
            onChange={onChangeCurrency}
          />
          <SelectInput
            id="network"
            name="network"
            label={
              <FormattedMessage
                id="page.trading.popup.wallet.chooseNetwork"
                defaultMessage="Choose network"
              />
            }
            control={control}
            options={networkOptions}
            rules={REQUIRED_FIELD_VALIDATION}
          />
        </>
      )}
      {!cryptoTransaction && cryptoWallets.data?.length ? (
        <div className="wallet-list _flex _flex-w row">
          {cryptoWallets.data.map((cryptoWallet) => (
            <div className="wallet col c-xs-6" key={cryptoWallet.id}>
              <input
                type="radio"
                id={String(cryptoWallet.id)}
                autoComplete="off"
                value={cryptoWallet.id}
                {...register('target_wallet', { required: true })}
              />
              <label htmlFor={String(cryptoWallet.id)}>
                <div
                  className={`wallet-item ${
                    selectedWalletId === cryptoWallet.id ? 'selected' : ''
                  } ${errors.target_wallet ? 'error' : ''}`}
                  onClick={() => {
                    if (selectedWalletId !== cryptoWallet.id) {
                      setSelectedWalletId(cryptoWallet.id);
                    }
                  }}
                >
                  <div className="wallet-icon">
                    <i className={`${cryptoWallet.crypto_type?.toLowerCase()}`}></i>
                  </div>
                  <div className="wallet-desc">
                    <span>
                      {cryptoWallets[cryptoWallet.crypto_type?.toLowerCase()]}{' '}
                      {cryptoWallet.crypto_type === 'USDT' &&
                        cryptoWallet.crypto_network &&
                        `(${cryptoWallet.crypto_network})`}
                    </span>
                    <span onClick={() => setFullWallet(true)}>
                      {!fullWallet
                        ? cryptoWallet?.wallet.slice(0, 5) + '...' + cryptoWallet?.wallet.slice(-5)
                        : cryptoWallet?.wallet}
                    </span>
                  </div>
                </div>
              </label>
            </div>
          ))}
        </div>
      ) : null}
      {cryptoTransaction ? <TransactionInfo cryptoTransaction={cryptoTransaction} /> : null}
      {!cryptoTransaction && !cryptoWallets.data?.length ? (
        <div className="transaction__empty _flex _flex-col _flex-xy-c">
          <span>
            {cryptoWallets.isLoading ? (
              'Loading..'
            ) : (
              <FormattedMessage id="page.trading.popup.wallet.none" defaultMessage="No Wallets" />
            )}
          </span>
        </div>
      ) : null}
      {!cryptoTransaction && cryptoWallets.data.length > 0 && cryptoWallets.hasMore ? (
        <div className="_flex _flex-j-c _m-b-10">
          <Button
            showSpinner={cryptoWallets.isLoading}
            isDisabled={cryptoWallets.isLoading}
            onClick={depositCryptoWalletsRequest}
          >
            <FormattedMessage id="page.trading.popup.wallet.loadMore" defaultMessage="Load more" />
          </Button>
        </div>
      ) : null}
      {!cryptoTransaction ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="_flex _flex-j-e">
            <Button className="_m-r-10" buttonType="outline" onClick={handleOpenDepositModal}>
              <FormattedMessage
                id="page.trading.popup.changePassword.btn.cancel"
                defaultMessage="Cancel"
              />
            </Button>
            <Button
              type="submit"
              tag="button"
              showSpinner={isPaymentRequestLoading}
              isDisabled={isPaymentRequestLoading}
            >
              <FormattedMessage id="page.trading.popup.wallet.ok" defaultMessage="OK" />
            </Button>
          </div>
        </form>
      ) : null}
    </div>
  );
};
