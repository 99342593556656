import { useForm, useWatch } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { Button } from '../../components/Button/Button';
import { SelectInput } from '../../components/Form/SelectInput/SelectInput';
import DatePickerRange from '../../components/Form/DatePickerRange/DatePickerRange';
import { formatAccountSelectOptions } from '../../components/Form/_helpers/formatAccountSelectOptions';
import { useAppSelector } from '../../store';
import { selectModalProps } from '../../store/modal';
import { AccountSelectOption } from '../../components/Form/_helpers/AccountSelectOption';
import { useGetSymbolOptions } from 'hooks/useGetSymbols';

export const HISTORY_FILTERS_MODAL_KEY = 'HISTORY_FILTERS_MODAL_KEY';

const stateOptions = ['Position', 'Pending', 'History'].map((s) => ({ value: s, label: s }));

const HistoryFilterModal = ({ accountsData, closeModal, onSubmitFilter }) => {
  const intl = useIntl();
  const filter = useAppSelector(selectModalProps);

  const { control, setValue, handleSubmit } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      open: filter?.open,
      logins: filter?.logins ? formatAccountSelectOptions([filter.logins])[0] : null,
      close: filter?.close,
      symbols: filter?.symbols ? { value: filter.symbols[0], label: filter.symbols[0] } : null,
      state: filter?.state ? { value: filter.state, label: filter.state } : null,
    },
  });

  const fields = useWatch({ control });

  const { symbols, isLoading } = useGetSymbolOptions(fields.logins?.value);

  const onSubmit = (data: any) => {
    onSubmitFilter({
      logins: data.logins?.value ? [data.logins.value] : undefined,
      symbols: data?.symbols?.value ? [data.symbols.value] : undefined,
      state: data?.state?.value ?? undefined,
      open_date_from: data.open?.from ?? undefined,
      open_date_to: data.open?.to ?? undefined,
      closing_date_from: data.close?.from ?? undefined,
      closing_date_to: data.close?.to ?? undefined,
    });
    closeModal();
  };

  return (
    <div className="history-modal">
      <form onSubmit={handleSubmit(onSubmit)} className="_overflow-h">
        <div className="row">
          <div className="col c-md-6">
            <SelectInput
              id="filter_logins"
              name="logins"
              label={
                <FormattedMessage
                  id="page.trading.history.tradingAccount.title"
                  defaultMessage="Trading account"
                />
              }
              control={control}
              options={formatAccountSelectOptions(accountsData)}
              formatOptionLabel={AccountSelectOption}
              onChange={() => {
                if (fields.symbols?.value) {
                  setValue('symbols', null);
                }
              }}
            />
          </div>
          <div className="col c-md-6">
            <SelectInput
              id="filter_trading_positions_symbol"
              name="symbols"
              label={<FormattedMessage id="table.th.symbol" defaultMessage="Symbol" />}
              isLoading={isLoading}
              control={control}
              options={symbols}
            />
          </div>

          <div className="col c-md-6">
            <SelectInput
              id="filter_trading_positions_state"
              name="state"
              label={<FormattedMessage id="table.th.state" defaultMessage="State" />}
              control={control}
              options={stateOptions}
            />
          </div>

          <div className="col c-md-6">
            <DatePickerRange
              name="open"
              control={control}
              label={
                <FormattedMessage
                  id="page.trading.history.range.open.title"
                  defaultMessage="Open time range"
                />
              }
              placeholder={intl.formatMessage({
                id: 'page.trading.history.range.placeholder',
                defaultMessage: 'From - To',
              })}
            />
          </div>
          <div className="col c-md-6">
            <div className="datapicker">
              <DatePickerRange
                control={control}
                name="close"
                label={
                  <FormattedMessage
                    id="page.trading.history.range.close.title"
                    defaultMessage="Close time range"
                  />
                }
                placeholder={intl.formatMessage({
                  id: 'page.trading.history.range.placeholder',
                  defaultMessage: 'From - To',
                })}
              />
            </div>
          </div>
        </div>
        <div className="form-buttons _flex _flex-a-c _flex-j-e _m-t-50">
          <Button buttonType="outline" className="_m-r-15" onClick={closeModal}>
            <FormattedMessage id="newAccountPopup.cancelBtn" defaultMessage="Cancel" />
          </Button>
          <Button
            tag="button"
            type="submit"
            isDisabled={Object.values(fields).every((value) => !value)}
          >
            <FormattedMessage id="page.trading.history.form.apply" defaultMessage="Apply" />
          </Button>
        </div>
      </form>
    </div>
  );
};

export default HistoryFilterModal;
