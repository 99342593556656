import { useAppDispatch, useAppSelector } from "../../../store";
import { useEffect } from "react";
import { getAccountInfo, selectHeaderInfo } from "../../../store/headerInfo";
import { Button } from "../../../components/Button/Button";
import { FormattedMessage } from "react-intl";
import { Burger } from "../Burger/Burger";
import { ProfileDropdown } from "../ProfileDropdown/ProfileDropdown";
import { CurrencyInfo } from "../CurrencyInfo/CurrencyInfo";
import { DEPOSIT_MODAL_KEY, DepositModal } from "../../../modals/DepositModal/DepositModal";
import { WALLET_MODAL_KEY, WalletModal } from "../../../modals/WalletModal/WalletModal";
import { closeModal, openModal } from "../../../store/modal";
import { ModalTemplate } from "../../../components/Modal/components/ModalTemplate/ModalTemplate";
import { selectTheme } from "../../../store/theme";
import './Header.scss';

export const Header = ({ switchMenu, isOpened }) => {
    const dispatch = useAppDispatch();
    const { logo_src } = useAppSelector(selectTheme);
    const headerInfo = useAppSelector(selectHeaderInfo);

    useEffect(() => {
        dispatch(getAccountInfo());
    }, []);

    const handleCloseModal = () => {
        dispatch(closeModal());
    };

    return (
        <>
            <div className="private-layout-header _pos-f _w-100 _flex _flex-a-c _flex-j-e">
                <Burger onClick={switchMenu} opened={isOpened}/>
                <div className="header-logo _img-wrap _flex-sh-0 _m-r-auto">
                    <img src={logo_src} alt='logo'/>
                </div>
                <div className="client-name _truncate _text-r _m-r-15">
                    {headerInfo.user.first_name + " " + headerInfo.user.last_name}
                </div>
                <CurrencyInfo />
                <Button onClick={() => dispatch(openModal({ key: DEPOSIT_MODAL_KEY }))}>
                    <FormattedMessage id="page.finance.deposit.btn" defaultMessage="Deposit"/>
                </Button>
                <ProfileDropdown/>
            </div>
            <ModalTemplate
                id={DEPOSIT_MODAL_KEY}
                title={<FormattedMessage id="page.finance.deposit.title" defaultMessage="Deposit"/>}
                onClose={handleCloseModal}
                size="sm">
                <DepositModal closeModal={handleCloseModal}/>
            </ModalTemplate>
            <ModalTemplate
                id={WALLET_MODAL_KEY}
                title={<FormattedMessage id="page.finance.deposit.popup.wallet" defaultMessage="Crypto deposit"/>}
                onClose={handleCloseModal}
                size="sm">
                <WalletModal />
            </ModalTemplate>
        </>
    )
}