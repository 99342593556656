import { FormattedMessage } from 'react-intl';
import { Dispatch, SetStateAction } from "react";
import { GetTableDataOptions, ITableLink, ITableMeta } from "../../types/table";

type TablePaginationProps = {
    perPage?: string;
    tableMeta: Partial<ITableMeta>;
    tableLinks: Partial<ITableLink>;
    setPerPageCount: Dispatch<SetStateAction<string>>;
    onChange: (options: GetTableDataOptions) => void;
}

export const TablePagination = ({
                             perPage = '10',
                             tableMeta,
                             tableLinks,
                             setPerPageCount,
                             onChange
                         }: TablePaginationProps) => {

    return (
        <div className="table-pagination _flex _flex-a-c _flex-j-e _fz-14 _m-t-5">
            <div className="table-pagination-buttons _flex _flex-a-c">
                <button onClick={() => onChange({ navigate: 'first' })} disabled={tableMeta?.current_page === 1}>
                    <i className="fi fi-rr-angle-double-left"/>
                </button>
                <button onClick={() => onChange({ navigate: 'prev' })} disabled={!tableLinks?.prev}>
                    <i className="fi fi-rr-angle-left"/>
                </button>
                <button onClick={() => onChange({ navigate: 'next' })} disabled={!tableLinks?.next}>
                    <i className="fi fi-rr-angle-right"/>
                </button>
                <button onClick={() => onChange({ navigate: 'last' })} disabled={tableMeta?.current_page === tableMeta?.last_page}>
                    <i className="fi fi-rr-angle-double-right"/>
                </button>
            </div>
            <div className="table-pagination-wrap _flex _flex-a-c">
                <div className="table-pagination-form-title">
                    <FormattedMessage id="table.pagination.page" defaultMessage="Page"/>
                    <strong className="_p-x-5">
                        {tableMeta?.current_page}
                        <span className="_p-x-5">
                            <FormattedMessage id="table.pagination.tof" defaultMessage="of"/>
                        </span>
                        {tableMeta?.last_page}
                    </strong>
                </div>
                <div className='table-pagination-form'>
                    <FormattedMessage id="table.pagination.goPage" defaultMessage="Go to Page"/>
                    <input
                        className="_m-x-5"
                        type="number"
                        max={tableMeta?.last_page}
                        min={1}
                        value={tableMeta?.current_page}
                        onChange={(e) => {
                            const value = e.target.value ? Number(e.target.value) : 0;
                            if (value > tableMeta?.last_page) {
                                e.target.value = tableMeta ? `${tableMeta.last_page}` : '';
                                return;
                            }
                            onChange({ page: e.target.value })
                        }}
                    />
                </div>
                <select
                    value={perPage}
                    onChange={(e) => {
                        setPerPageCount(e.target.value);
                        onChange({ perPage: e.target.value });
                    }}
                >
                    {[10, 20, 30, 40, 50].map((perPage) => (
                        <option key={perPage} value={perPage}>
                            {perPage}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
};

export default TablePagination;