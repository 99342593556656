import { useEffect, useState } from 'react';
import { getTradingHistorySymbols } from 'api/trading';

export const useGetSymbolOptions = (tradingAcc?: string) => {
  const [symbols, setSymbols] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const request = (logins?: string[]) => {
    setIsLoading(true);
    getTradingHistorySymbols(logins)
      .then((response) => {
        const options = response.data.data.tradedSymbols.map((s) => ({ label: s, value: s }));
        setSymbols(options);
      })
      .catch(console.log)
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (tradingAcc) {
      request([tradingAcc]);
    } else {
      request();
    }
  }, [tradingAcc]);

  return { symbols, isLoading };
};
