import { useAppSelector } from '../../store';
import { selectPaymentIsFintegrity } from 'store/payments';
import { WalletModalContentFintegrity } from './WalletModalContentFintegrity';
import { WalletModalContent } from './WalletModalContent';

export const WALLET_MODAL_KEY = 'WALLET_MODAL_KEY';

export const WalletModal = () => {
  const isFintegrity = useAppSelector(selectPaymentIsFintegrity);

  // TODO: вынести общий функционал
  return isFintegrity ? <WalletModalContentFintegrity /> : <WalletModalContent />;
};
