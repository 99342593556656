import { useState } from 'react';
import { useIntl } from 'react-intl';
import { DEPOSIT_TYPE } from '../../constants/finance';
import { useAppDispatch } from '../../store';
import { addToast } from '../../store/toast';
import { addDeposit } from '../../api/deposit';
import { FormProvider, useForm } from 'react-hook-form';
import { getResponseErrorMessage } from '../../utils/getResponseErrorMessage';
import { WithdrawModalStep1 } from './WithdrawModalStep1';
import { WithdrawModalStep2 } from './WithdrawModalStep2';
import './WithdrawModal.scss';

type SubmitDataTypes = {
  amount: string;
  amount_type: string;
  trading_account_id: string;
  type: string;
  method: string;
  crypto_type: string;
  wallet_address: string;
  card_number: string;
};

export type withdrawWalletStep = 'start' | 'crypto_select' | 'card_select' | 'iban_select';

export const WITHDRAW_MODAL_KEY = 'WITHDRAW_MODAL_KEY';

export const WITHDRAW_CARD_METHODS = ['credit_card_ca', 'bank_card_ca'];

export const WithdrawModal = ({ closeModal }) => {
  const dispatch = useAppDispatch();
  const form = useForm({
    defaultValues: {
      amount: '',
      crypto_type: { value: 'btc', label: 'Bitcoin' },
      wallet: '',
      card: '',
      payment_method: undefined,
      fromWithdraw: undefined,
    },
  });
  const intl = useIntl();
  const [walletStep, setWalletStep] = useState<withdrawWalletStep>('start');
  const [isLoading, setLoadStatus] = useState(false);

  // TODO: Replace the amount validation with availableWithdraw?.available_balance_amount
  // const availableWithdraw = useWithdrawAvailability(fromWithdraw);

  const onSubmit = (values) => {
    setLoadStatus(true);
    const paymentMethod = values?.payment_method?.value;
    const postData: Partial<SubmitDataTypes> = {
      amount: values?.amount,
      amount_type: values?.fromWithdraw?.data?.amount_type,
      trading_account_id: values?.fromWithdraw?.data?.trading_account,
      type: DEPOSIT_TYPE.WITHDRAW,
      method: values?.payment_method?.value,
    };

    if (paymentMethod === 'Crypto') {
      if (!values?.crypto_type || !values?.wallet) {
        dispatch(
          addToast({
            type: 'error',
            message: intl.formatMessage({ id: 'form.error.fillRequired' }),
            timeout: 3000,
          }),
        );
        setLoadStatus(false);
        return;
      }
      postData.crypto_type = values?.crypto_type.value.toUpperCase();
      postData.wallet_address = values?.wallet;
    } else if (WITHDRAW_CARD_METHODS.includes(paymentMethod) || paymentMethod === 'iban_ca') {
      if (!values?.card) {
        dispatch(
          addToast({
            type: 'error',
            message: intl.formatMessage({ id: 'form.error.fillRequired' }),
            timeout: 3000,
          }),
        );
        setLoadStatus(false);
        return;
      }
      postData.card_number = values.card;
    }

    addDeposit(postData)
      .then(() => {
        dispatch(
          addToast({
            type: 'success',
            title: intl.formatMessage({ id: 'status.success.title', defaultMessage: 'Success' }),
            message: intl.formatMessage({ id: 'transaction.withdraw.alert.created' }),
            timeout: 5000,
          }),
        );
        closeModal();
      })
      .catch((err) => {
        dispatch(
          addToast({
            type: 'error',
            message: getResponseErrorMessage(err),
            timeout: 5000,
          }),
        );
      })
      .finally(() => {
        setLoadStatus(false);
      });
  };

  return (
    <div className="withdraw-modal">
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <WithdrawModalStep1 {...{ isLoading, walletStep, setWalletStep, onSubmit, closeModal }} />
          {walletStep !== 'start' ? (
            <WithdrawModalStep2 {...{ isLoading, walletStep, setWalletStep }} />
          ) : null}
        </form>
      </FormProvider>
    </div>
  );
};
