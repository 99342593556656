import { FormattedMessage } from "react-intl";

export const TABLE_TRADING_COLUMNS_NAMES = [
    {
        Header: <FormattedMessage id="table.th.order" defaultMessage="Order" />,
        accessor: 'order_id',
      },
      {
        Header: <FormattedMessage id="table.th.account" defaultMessage="Account" />,
        accessor: 'account',
      },
      {
        Header: <FormattedMessage id="table.th.symbol" defaultMessage="Symbol" />,
        accessor: 'symbol',
      },
      {
        Header: <FormattedMessage id="table.th.opening_date" defaultMessage="Open Date" />,
        accessor: 'opening_date',
      },
      {
        Header: <FormattedMessage id="table.th.closing_date" defaultMessage="Close Date" />,
        accessor: 'closing_date',
      },
      {
        Header: <FormattedMessage id="table.th.type" defaultMessage="Type" />,
        accessor: 'type',
      },
      {
        Header: <FormattedMessage id="table.th.state" defaultMessage="State" />,
        accessor: 'state',
      },
      {
        Header: <FormattedMessage id="table.th.units" defaultMessage="Units" />,
        accessor: 'units',
      },
      {
        Header: <FormattedMessage id="table.th.volume" defaultMessage="Volume" />,
        accessor: 'volume',
      },
      {
        Header: <FormattedMessage id="table.th.lots" defaultMessage="Lots" />,
        accessor: 'lots',
      },
      {
        Header: <FormattedMessage id="table.th.opening_price" defaultMessage="Open Price" />,
        accessor: 'opening_price',
      },
      {
        Header: <FormattedMessage id="table.th.closing_price" defaultMessage="Close Price" />,
        accessor: 'closing_price',
      },
      {
        Header: 'SL',
        accessor: 'sl',
      },
      {
        Header: 'TP',
        accessor: 'tp',
      },
      {
        Header: <FormattedMessage id="table.th.commission" defaultMessage="Commission" />,
        accessor: 'commission',
      },
      {
        Header: <FormattedMessage id="table.th.profit" defaultMessage="Profit" />,
        accessor: 'profit',
      },
];